<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-if="!getLoading && requestData">
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row justify="space-between" align="center" class="mb-5">
        <v-col cols="auto">
          <h1 class="text-h4 mb-0">{{ 'bookingSummary'|localize }}</h1>
        </v-col>
        <v-col cols="auto" class="text-right">
          <h6 class="text-h6 mb-0">
            {{ 'status'|localize }}:
            <v-progress-circular v-if="confirmationLoading" indeterminate :width="2" size="20" color="primary" :rounded="true" class="mx-2" />
            <template v-else>
              <v-icon :color="requestData['confirmed'] ? 'success' : 'silver-chalice'" size="20" class="mx-2 mb-1">
                mdi-check
              </v-icon>
              <span class="font-weight-regular">{{ bookingStatus }}</span>
            </template>
          </h6>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-container class="px-4 pt-8">
          <v-row class="mb-4">
            <v-col cols="5">
              <h5 class="text-h5 mb-4">{{ 'senderAddress'|localize }}</h5>
              <template v-if="requestData['addresses'].sender.company">
                <h6 class="text-subtitle-1 mb-1">{{ 'company'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].sender.company }}</h6>
                <template v-if="requestData['addresses'].sender.vat_number">
                  <h6 class="text-subtitle-1 mb-1">{{ 'vatNumber'|localize }}</h6>
                  <h6 class="text-body-1 mb-4">{{ requestData['addresses'].sender.vat_number }}</h6>
                </template>
              </template>
              <template v-else>
                <h6 class="text-subtitle-1 mb-1">{{ 'firstName'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].sender.first_name }}</h6>
                <h6 class="text-subtitle-1 mb-1">{{ 'lastName'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].sender.last_name }}</h6>
              </template>
              <template v-if="requestData['addresses'].sender.contact_person">
                <h6 class="text-subtitle-1 mb-1">{{ 'contactPerson'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].sender.contact_person }}</h6>
              </template>
              <template v-if="requestData['addresses'].sender.email">
                <h6 class="text-subtitle-1 mb-1">{{ 'email'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].sender.email }}</h6>
              </template>
              <template v-if="requestData['addresses'].sender.phone_number">
                <h6 class="text-subtitle-1 mb-1">{{ 'phoneNumber'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].sender.phone_number }}</h6>
              </template>
              <h6 class="text-subtitle-1 mb-1">{{ 'senderAddress'|localize }}</h6>
              <h6 class="text-body-1">{{ requestData['addresses'].sender['full_address'] }}</h6>
            </v-col>
            <v-col cols="5">
              <h5 class="text-h5 mb-4">{{ 'receiverAddress'|localize }}</h5>
              <template v-if="requestData['addresses'].receiver.company">
                <h6 class="text-subtitle-1 mb-1">{{ 'company'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].receiver.company }}</h6>
                <template v-if="requestData['addresses'].receiver.vat_number">
                  <h6 class="text-subtitle-1 mb-1">{{ 'vatNumber'|localize }}</h6>
                  <h6 class="text-body-1 mb-4">{{ requestData['addresses'].receiver.vat_number }}</h6>
                </template>
              </template>
              <template v-else>
                <h6 class="text-subtitle-1 mb-1">{{ 'firstName'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].receiver.first_name }}</h6>
                <h6 class="text-subtitle-1 mb-1">{{ 'lastName'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].receiver.last_name }}</h6>
              </template>
              <template v-if="requestData['addresses'].receiver.contact_person">
                <h6 class="text-subtitle-1 mb-1">{{ 'contactPerson'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].receiver.contact_person }}</h6>
              </template>
              <template v-if="requestData['addresses'].receiver.email">
                <h6 class="text-subtitle-1 mb-1">{{ 'email'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].receiver.email }}</h6>
              </template>
              <template v-if="requestData['addresses'].receiver.phone_number">
                <h6 class="text-subtitle-1 mb-1">{{ 'phoneNumber'|localize }}</h6>
                <h6 class="text-body-1 mb-4">{{ requestData['addresses'].receiver.phone_number }}</h6>
              </template>
              <h6 class="text-subtitle-1 mb-1">{{ 'receiverAddress'|localize }}</h6>
              <h6 class="text-body-1">{{ requestData['addresses'].receiver['full_address'] }}</h6>
            </v-col>
            <v-col cols="2">
              <h6 class="text-subtitle-1 mb-1">{{ 'transportMode'|localize }}</h6>
              <h6 class="text-body-1 d-flex align-center">
                <v-icon color="silver-chalice" class="mr-1">{{ TRANSPORT_ICONS[requestData.transport_mode] }}</v-icon>
                {{ requestData.transport_mode|localize }}
              </h6>
            </v-col>
          </v-row>
          <template v-if="requestData['addresses'].collection || requestData['addresses'].delivery">
            <v-divider class="my-10"/>
            <v-row class="mb-4">
              <v-col cols="5" v-if="requestData['addresses'].collection">
                <h5 class="text-h5 mb-4">{{ 'collectionAddress'|localize }}</h5>
                <template v-if="requestData['addresses'].collection.is_terminal">
                  <h6 class="text-subtitle-1 mb-1">{{ 'isTerminalDirectionFrom'|localize }}</h6>
                  <h6 class="text-body-1">{{ requestData['addresses'].collection['full_address'] }}</h6>
                </template>
                <template v-else>
                  <template v-if="requestData['addresses'].collection.company">
                    <h6 class="text-subtitle-1 mb-1">{{ 'company'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].collection.company }}</h6>
                  </template>
                  <template v-else>
                    <h6 class="text-subtitle-1 mb-1">{{ 'firstName'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].collection.first_name }}</h6>
                    <h6 class="text-subtitle-1 mb-1">{{ 'lastName'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].collection.last_name }}</h6>
                  </template>
                  <template v-if="requestData['addresses'].collection.contact_person">
                    <h6 class="text-subtitle-1 mb-1">{{ 'contactPerson'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].collection.contact_person }}</h6>
                  </template>
                  <template v-if="requestData['addresses'].collection.email">
                    <h6 class="text-subtitle-1 mb-1">{{ 'email'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].collection.email }}</h6>
                  </template>
                  <template v-if="requestData['addresses'].collection.phone_number">
                    <h6 class="text-subtitle-1 mb-1">{{ 'phoneNumber'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].collection.phone_number }}</h6>
                  </template>
                  <h4 class="text-subtitle-1 mb-1">{{ 'collectionAddress'|localize }}</h4>
                  <h6 class="text-body-1">{{ requestData['addresses'].collection['full_address'] }}</h6>
                </template>
              </v-col>
              <v-col cols="5" v-if="requestData['addresses'].delivery">
                <h5 class="text-h5 mb-4">{{ 'deliveryAddress'|localize }}</h5>
                <template v-if="requestData['addresses'].delivery.is_terminal">
                  <h6 class="text-subtitle-1 mb-1">{{ 'isTerminalDirectionTo'|localize }}</h6>
                  <h6 class="text-body-1">{{ requestData['addresses'].delivery['full_address'] }}</h6>
                </template>
                <template v-else>
                  <template v-if="requestData['addresses'].delivery.company">
                    <h6 class="text-subtitle-1 mb-1">{{ 'company'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].delivery.company }}</h6>
                  </template>
                  <template v-else>
                    <h6 class="text-subtitle-1 mb-1">{{ 'firstName'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].delivery.first_name }}</h6>
                    <h6 class="text-subtitle-1 mb-1">{{ 'lastName'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].delivery.last_name }}</h6>
                  </template>
                  <template v-if="requestData['addresses'].delivery.contact_person">
                    <h6 class="text-subtitle-1 mb-1">{{ 'contactPerson'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].delivery.contact_person }}</h6>
                  </template>
                  <template v-if="requestData['addresses'].delivery.email">
                    <h6 class="text-subtitle-1 mb-1">{{ 'email'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].delivery.email }}</h6>
                  </template>
                  <template v-if="requestData['addresses'].delivery.phone_number">
                    <h6 class="text-subtitle-1 mb-1">{{ 'phoneNumber'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{ requestData['addresses'].delivery.phone_number }}</h6>
                  </template>
                  <h6 class="text-subtitle-1 mb-1">{{ 'deliveryAddress'|localize }}</h6>
                  <h6 class="text-body-1">{{ requestData['addresses'].delivery['full_address'] }}</h6>
                </template>
              </v-col>
            </v-row>
          </template>
          <v-divider class="my-10"/>
          <v-row>
            <v-col cols="auto">
              <h5 class="text-h5">{{ 'goods'|localize }}</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <GoodComponent
                v-for="(item, index) in requestData.goods"
                :currentItem="item"
                :goodTypes="goodTypesSorted.all"
                :transportMode="requestData['transport_modes']"
                :item="item"
                :number="index"
                :key="item.id"
                :disabled="true"
              />
            </v-col>
          </v-row>
          <GoodsSummary
            :totalPackages="requestData.goods.length"
            :totalWeight="requestData.weight"
            :totalVolume="requestData.volume"
          />
          <v-divider class="mb-10"/>
          <v-row>
            <v-col cols="auto">
              <h5 class="text-h5">{{ 'expectations'|localize }}</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <h6 class="text-subtitle-1 mb-1">{{ 'loading'|localize }}</h6>
              <h6 class="text-body-1 mb-4" v-html="$options.filters.columnizeDates(requestData.loading_date)"/>
              <h6 class="text-subtitle-1 mb-1">{{ 'slotTimeLoading'|localize }}</h6>
              <h6 class="text-body-1 mb-10">
                <template v-if="requestData.slot_time_loading">{{ requestData.slot_time_loading.from }} - {{ requestData.slot_time_loading.to }}</template>
                <template v-else>--</template>
              </h6>
            </v-col>
            <v-col cols="3">
              <h6 class="text-subtitle-1 mb-1">{{ 'unloading'|localize }}</h6>
              <h6 class="text-body-1 mb-4" v-html="$options.filters.columnizeDates(requestData.unloading_date)"/>
              <h6 class="text-subtitle-1 mb-1">{{ 'slotTimeDelivery'|localize }}</h6>
              <h6 class="text-body-1 mb-10">
                <template v-if="requestData.slot_time_delivery">{{ requestData.slot_time_delivery.from }} - {{ requestData.slot_time_delivery.to }}</template>
                <template v-else>--</template>
              </h6>
            </v-col>
            <v-col cols="3">
              <h6 class="text-subtitle-1 mb-1">{{ 'deliveryTerms'|localize }}</h6>
              <h6 class="text-body-1 mb-10">{{ requestData.delivery_terms }}</h6>
            </v-col>
          </v-row>
          <v-divider class="mb-10"/>
          <v-row>
            <v-col cols="auto">
              <h5 class="text-h5">{{ 'requirements'|localize }}</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <h6 class="text-subtitle-1 mb-1 d-flex align-center">
                <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS]">{{ SPECIAL_REQUIREMENTS_ICONS[SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS] }}</v-icon>
                {{ 'dangerous_goods'|localize }}
              </h6>
              <h6 class="text-body-1">
                <template v-if="requestData.dangerous_goods_number">
                  UN-{{ requestData.dangerous_goods_number }} /
                  <template v-if="requestData['attachment_name']">
                    <a :href="requestData['attachment_link']" target="_blank">{{ requestData['attachment_name'] }}</a>
                  </template>
                  <template v-else>#</template>
                </template>
                <template v-else>--</template>
              </h6>
            </v-col>
            <v-col cols="6">
              <h6 class="text-subtitle-1 mb-1 d-flex align-center">
                <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS]">{{ SPECIAL_REQUIREMENTS_ICONS[SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS] }}</v-icon>
                {{ 'tempered_goods'|localize }}
              </h6>
              <h6 class="text-body-1">{{ requestData.tempered_goods ? `${requestData.tempered_goods[0]}ºC - ${requestData.tempered_goods[1]}ºC` : '--' }}</h6>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pt-0">
              <h6 class="text-subtitle-1 mb-1">{{ 'goodsType'|localize }}</h6>
              <h6 class="text-body-1">{{ computedProductType }}</h6>
            </v-col>
            <v-col cols="3" class="pt-0">
              <h6 class="text-subtitle-1 mb-1">{{ 'tailLiftLoading'|localize }}</h6>
              <h6 class="text-body-1">
                <template v-if="requestData.tail_lift_loading">{{ 'yes'|localize }}</template>
                <template v-else>{{ 'no'|localize }}</template>
              </h6>
            </v-col>
            <v-col cols="3" class="pt-0">
              <h6 class="text-subtitle-1 mb-1">{{ 'tailLiftDelivery'|localize }}</h6>
              <h6 class="text-body-1">
                <template v-if="requestData.tail_lift_delivery">{{ 'yes'|localize }}</template>
                <template v-else>{{ 'no'|localize }}</template>
              </h6>
            </v-col>
          </v-row>
          <v-divider class="my-10"/>
          <v-row>
            <v-col cols="6">
              <h6 class="text-subtitle-1 mb-1">{{ 'containerLoadingUnloadingSweden'|localize }}</h6>
              <h6 class="text-body-1">
                <template v-if="requestData.container_loading_unloading">{{ requestData.container_loading_unloading|localize }}</template>
                <template v-else>--</template>
              </h6>
            </v-col>
            <v-col cols="6">
              <h6 class="text-subtitle-1 mb-1">{{ 'customClearance'|localize }}</h6>
              <h6 class="text-body-1">
                <template v-if="requestData.custom_clearance">{{ 'needHelp'|localize }}</template>
                <template v-else-if="requestData.custom_clearance === false">{{ 'doItOurselves'|localize }}</template>
                <template v-else>--</template>
              </h6>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pt-0">
              <h6 class="text-subtitle-1 mb-1">{{ 'otherInformation'|localize }}</h6>
              <h6 class="text-body-1">{{ requestData.other_information ? requestData.other_information : '--' }}</h6>
            </v-col>
            <v-col cols="6" class="pt-0">
              <h6 class="text-subtitle-1 mb-1">{{ 'attachedDocuments'|localize }}</h6>
              <h6 class="text-body-1">
                <template v-if="requestData['booking_attachments'] && requestData['booking_attachments'].length > 0">
                  <span v-for="attachment in requestData['booking_attachments']" v-bind:key="attachment.name">
                    <a :href="attachment['link']" target="_blank">
                      {{ attachment['name'] }}
                    </a><br/>
                  </span>
                </template>
                <template v-else>--</template>
              </h6>
            </v-col>
          </v-row>
          <v-divider class="my-10"/>
          <v-row class="d-flex justify-space-between">
            <v-col cols="auto">
              <v-btn
                color="primary" rounded depressed @click="backToPrevious"
              >
                {{ 'back'|localize }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
    <SimpleDialog
      ref="confirmationDialog"
      :title="confirmationTitle"
      :text="confirmationText"
      :close-button="true"
    />
  </v-container>
</template>

<script>
  import localize from '@/filters/localize';
  import api from '@/api/api';
  import {TRANSPORT_ICONS} from '@/enums/transportMode';
  import GoodComponent from '@/components/GoodComponent';
  import GoodsSummary from '@/components/GoodsSummary';
  import methods from '@/helpers/methods';
  import SimpleDialog from '@/components/common/SimpleDialog';
  import {
    SPECIAL_REQUIREMENTS_COLORS,
    SPECIAL_REQUIREMENTS_ICONS,
    SPECIAL_REQUIREMENTS_TYPES,
  } from '@/enums/specialRequirements';

  export default {
    name: 'BookingSummary',
    components: {SimpleDialog, GoodComponent, GoodsSummary},

    data() {
      return {
        TRANSPORT_ICONS,
        requestId: this.$router.currentRoute.query.request_id,
        proposalId: this.$router.currentRoute.query.proposal_id,
        transportMode: this.$router.currentRoute.query.mode,
        getLoading: false,
        confirmationLoading: false,
        requestData: null,
        confirmationTitle: '',
        confirmationText: '',
      };
    },

    async mounted() {
      let confirmationData = methods.getCookie('confirmation');
      let bookingData = null;
      try {
        this.getLoading = true;
        if (this.isCompany) {
          bookingData = await this.getBookingSummaryCompany();
          if (!bookingData.data['confirmed'] && confirmationData) {
            let isConfirmed = await this.bookingConfirmation();
            if (isConfirmed) {
              bookingData = await this.getBookingSummaryCompany();
            }
          }
        }
        if (this.isCustomer) bookingData = await api.getBookingSummaryCustomer(this.proposalId);
        if (this.isAdmin) bookingData = await api.getBookingSummaryAdmin(this.proposalId);
        this.requestData = bookingData && bookingData.data ? bookingData.data : null;
        this.getLoading = false;
      } catch (e) {
        this.getLoading = false;
      }
    },

    computed: {
      SPECIAL_REQUIREMENTS_ICONS() {
        return SPECIAL_REQUIREMENTS_ICONS
      },
      SPECIAL_REQUIREMENTS_TYPES() {
        return SPECIAL_REQUIREMENTS_TYPES
      },
      SPECIAL_REQUIREMENTS_COLORS() {
        return SPECIAL_REQUIREMENTS_COLORS
      },
      isCompany() {
        return this.$store.getters.isCompany;
      },
      isCustomer() {
        return this.$store.getters.isCustomer;
      },
      isAdmin() {
        return this.$store.getters.isAdmin;
      },
      userData() {
        return this.$store.getters.userData;
      },
      goodTypesSorted() {
        return this.$store.getters.goodTypesSorted;
      },
      productTypes() {
        return this.$store.getters.productTypes;
      },
      breadcrumbsItems() {
        let breadcrumbs = [];
        if (this.isCompany) {
          breadcrumbs = [
            {
              text: localize('myRequests'),
              disabled: false,
              to: {name: 'company-requests'}
            },
            {
              text: localize('requestDetails'),
              to: {name: 'company-request-details', query: {request_id: this.requestId, mode: this.transportMode}, hash: '#quotes'},
              disabled: false,
            },
            {
              text: localize('bookingSummary'),
              disabled: true,
            },
          ];
        }
        if (this.isCustomer) {
          breadcrumbs = [
            {
              text: localize('myQuotes'),
              disabled: false,
              to: {name: 'customer-requests'}
            },
            {
              text: localize('quotes'),
              to: {name: 'request-details', query: {request_id: this.requestId}},
              disabled: false,
            },
            {
              text: localize('bookingSummary'),
              disabled: true,
            },
          ];
        }
        if (this.isAdmin) {
          breadcrumbs = [
            {
              text: localize('requests'),
              disabled: false,
              to: {name: 'admin-requests'}
            },
            {
              text: localize('quotes'),
              to: {name: 'admin-request-details', query: {request_id: this.requestId}, hash: '#quotes'},
              disabled: false,
            },
            {
              text: localize('bookingSummary'),
              disabled: true,
            },
          ];
        }
        return breadcrumbs;
      },
      computedProductType() {
        if (this.requestData.goods_type) {
          let existCheck = this.productTypes.findIndex(type => type.key === this.requestData.goods_type);
          if (existCheck >= 0) {
            return localize(this.requestData.goods_type);
          } else {
            return this.requestData.goods_type;
          }
        }
      },
      bookingStatus() {
        return this.requestData['confirmed'] ? localize('confirmed') : localize('not_confirmed');
      }
    },

    methods: {
      async getBookingSummaryCompany() {
        let bookingData = {};
        try {
          bookingData = await api.getBookingSummaryCompany({
            proposal_id: this.proposalId,
            company_id: this.userData.company.id
          });
        } catch (e) {
          if (e.response.status === 404) this.$router.push({name: '404'});
        }
        return bookingData;
      },
      async bookingConfirmation() {
        let isConfirmed = false;
        try {
          this.confirmationLoading = true;
          await api.bookingConfirmation({
            request_id: this.requestId,
            proposal_id: this.proposalId,
            mode: this.transportMode,
            company_id: this.userData.company.id
          });
          document.cookie = 'confirmation=;';
          this.confirmationLoading = false;
          isConfirmed = true;
          this.confirmationTitle = localize('confirmationTitle');
          this.confirmationText = localize('confirmationText');
          this.$refs.confirmationDialog.open();
        } catch (e) {
          this.confirmationLoading = false;
          this.confirmationTitle = localize('confirmationErrorTitle');
          this.confirmationText = localize('confirmationErrorText');
          this.$refs.confirmationDialog.open();
        }
        return isConfirmed;
      },
      backToPrevious() {
        if (this.isCompany) this.$router.push({name: 'company-request-details', query: {request_id: this.requestId, mode: this.transportMode}, hash: '#quotes'});
        if (this.isCustomer) this.$router.push({name: 'request-details', query: {request_id: this.requestId}});
        if (this.isAdmin) this.$router.push({name: 'admin-request-details', query: {request_id: this.requestId}, hash: '#quotes'});
      }
    },
  };
</script>
